<template>
  <div class="w-full">
    <form @submit.prevent="setNewPassword" novalidate>
      <div class="">
        <div class="text-white text-center mb-3">
          {{ $t('Please enter your email and new password below.') }}
        </div>
        <base-input
          :input-class="{'bg-grey-5': emailDisabled}"
          label-class="text-white"
          v-model="email"
          :placeholder="$t('E-mail *')"
          :validations="[
              {
                condition: !$v.email.required && $v.email.$error,
                text: $t('Field is required.')
              },
              {
                condition: !$v.email.email && $v.email.$error,
                text: $t('Please provide valid e-mail address.')
              }
            ]"
          :disabled="emailDisabled"
          name="email"
          type="email"
          :autocomplete="false"
          :focus="!emailDisabled"
          @blur="$v.email.$touch()"
        >{{ $t('Email *') }}
        </base-input>
        <base-input
          label-class="text-white"
          v-model="password"
          :placeholder="$t('New Password *')"
          :validations="[
            {
              condition: !$v.password.required && $v.password.$error,
              text: $t('Field is required.')
            },
            {
              condition: !$v.password.minLength && $v.password.$error,
              text: $t('Password must have at least 8 letters.')
            },
            {
              condition: !$v.password.containsUppercase && $v.password.$error,
              text: $t('Must contain at least one uppercase letter.')
            },
            {
              condition: !$v.password.containsLowercase && $v.password.$error,
              text: $t('Must contain at least one lowercase letter.')
            },
            {
              condition: !$v.password.containsNumber && $v.password.$error,
              text: $t('Must contain at least one number.')
            },
            {
              condition: !$v.password.containsSpecial && $v.password.$error,
              text: $t('Must contain at least one special character.')
            }
          ]"
          focus
          class="mt-4"
          type="password"
          name="password"
          autocomplete="off"
          @blur="$v.password.$touch()"
        >{{ $t('New Password *') }}
        </base-input>
        <base-input
          label-class="text-white"
          v-model="rPassword"
          :placeholder="$t('Confirm New Password *')"
          :validations="[
            {
              condition: !$v.rPassword.required && $v.rPassword.$error,
              text: $t('Field is required.')
            },
            {
              condition: !$v.rPassword.sameAsPassword && $v.rPassword.$error,
              text: $t('Passwords must be identical.')
            }
          ]"
          class="mt-4"
          type="password"
          name="password-confirm"
          autocomplete="off"
          @blur="$v.rPassword.$touch()"
        >{{ $t('Confirm New Password *') }}
        </base-input>
      </div>
      <button-full class="mt-5 w-full" type="submit">
        {{ $t('Set a New Password') }}
      </button-full>
    </form>
  </div>
</template>

<script>
import config from 'config'
import ButtonFull from 'theme/components/theme/ButtonFull'
import BaseInput from '../Form/BaseInput'
import {required, email, minLength, sameAs} from 'vuelidate/lib/validators'
import {ResetPassword} from 'src/modules/vsf-password-reset/components/ResetPassword'

export default {
  mixins: [ResetPassword],
  name: 'ResetPass',
  components: {
    ButtonFull,
    BaseInput
  },
  data() {
    return {
      email: this.$route.query.email || '',
      password: '',
      rPassword: '',
      emailDisabled: !!this.$route.query.email
    }
  },
  methods: {
    notifyUser(notificationData) {
      this.$store.dispatch('notification/spawnNotification', notificationData)
    },
    async resetPass() {
      try {
        await this.$store.dispatch('user/createPasswordByStoreId', {
          email: this.email,
          resetToken: this.$route.params.resetToken,
          newPassword: this.password,
          websiteId: config.defaultWebsiteId,
          storeId: config.defaultStoreId
        })
        this.notifyUser({
          type: 'success',
          message: this.$t('New Password has been set'),
          action1: { label: this.$t('OK') }
        })
      } catch (err) {
        this.notifyUser({
          type: 'error',
          message: this.$t(err.result) || this.$t('Error while creating new password'),
          action1: { label: this.$t('OK') }
        })
      }
    },
    setNewPassword() {
      this.resetPass().then(() => {
        this.$bus.$on('user-after-loggedin', res => {
          this.$router.push({name: 'my-account'})
        })
      })
    }
  },
  validations: {
    email: {
      required,
      email
    },
    password: {
      minLength: minLength(8),
      required,
      containsUppercase: function(value) {
        return /[A-Z]/.test(value)
      },
      containsLowercase: function(value) {
        return /[a-z]/.test(value)
      },
      containsNumber: function(value) {
        return /[0-9]/.test(value)
      },
      containsSpecial: function(value) {
        return /[#?!@$%^&*-]/.test(value)
      }
    },
    rPassword: {
      required,
      sameAsPassword: sameAs('password')
    }
  }
}
</script>
