<template>
  <div id="forgot-your-password" class="forgot relative bg-center bg-no-repeat bg-cover">
    <section class="container">
      <div class="flex align-center flex-col justify-center min-h-screen forgot__content relative z-1">
        <h1 class="uppercase text-white forgot__title text-center font-black mb-lg leading-lg">
          {{ $t('Forgot Your Password?') }}
        </h1>
        <div class="w-full">
          <template v-if="!passwordSent">
            <form @submit.prevent="sendEmail" novalidate>
              <div class="forgot-password">
                <p class="forgot-password__description font-serif font-normal text-2h6 text-white text-center italic mb-4">
                  {{ $t('Please enter your email address below.') }} <br> {{ $t('You will receive a link to reset your password.') }}
                </p>
                <base-input
                  class="forgot-password__input mb-sm relative"
                  :placeholder="$t('Email')"
                  :validations="[
                    {
                      condition: !$v.email.required && $v.email.$error,
                      text: $t('Field is required.')
                    },
                    {
                      condition: !$v.email.email && $v.email.$error,
                      text: $t('Please provide valid e-mail address.')
                    }
                  ]"
                  focus
                  name="email"
                  type="email"
                  v-model="email"
                  :input-class="'bg-grey-5 text-black'"
                >
                  <i class="ss-gizmo ss-mail text-brown-2 absolute z-1"></i>
                </base-input>
              </div>
              <button-full class="forgot-password__submit relative mb-5" type="submit">
                {{ $t('Submit') }}
                <i class="ss-gizmo ss-right absolute top-0 text-h5"></i>
              </button-full>
            </form>
            <router-link :to="localizedRoute('/customer/account/login')" :title="$t('Sign in')" class="forgot-password__link text-white text-average">
              <slot>
                <i class="ss-gizmo ss-navigateleft relative"></i>
                {{ $t('Go back') }}
              </slot>
            </router-link>
          </template>
          <template v-if="passwordSent">
            <p class="forgot-password__description bg-primary2 text-white py-3 px-5 mb-3 font-bold">
              {{ $t('We\'ve sent password reset instructions to your email.') }} <br> {{ $t('Check your inbox and follow the link.') }}
            </p>
            <button-full :link="localizedRoute('/customer/account/login')" :title="$t('Sign in')" class="forgot-password__submit relative mb-5">
              {{ $t('sign in') }}
              <i class="ss-gizmo ss-right absolute top-0 text-h5"></i>
            </button-full>
          </template>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import rootStore from '@vue-storefront/core/store'
import ButtonFull from 'theme/components/theme/ButtonFull'
import BaseInput from 'theme/components/core/blocks/Form/BaseInput'
import { required, email } from 'vuelidate/lib/validators'
import i18n from '@vue-storefront/i18n'

export default {
  mixins: [],
  name: 'ForgotYourPassword',
  components: {
    ButtonFull,
    BaseInput
  },
  data () {
    return {
      email: '',
      passwordSent: false
    }
  },
  validations: {
    email: {
      required,
      email
    }
  },
  beforeRouteEnter (to, from, next) {
    if (rootStore.getters['user/isLoggedIn']) {
      if (to.query.redirect) {
        next(to.query.redirect)
      } else {
        next({ name: 'my-account' })
      }
    }
    next()
  },
  methods: {
    close () {
      this.$bus.$emit('modal-hide', 'modal-signup')
    },
    sendEmail () {
      // todo: send email with reset password instructions

      if (this.$v.$invalid) {
        this.$v.$touch()
        this.$store.dispatch('notification/spawnNotification', {
          type: 'error',
          message: i18n.t('Please fix the validation errors'),
          action1: { label: i18n.t('OK') }
        })
        return
      }

      this.$bus.$emit('notification-progress-start', i18n.t('Resetting the password ... '))
      this.$store.dispatch('user/resetPassword', { email: this.email }).then((response) => {
        this.$bus.$emit('notification-progress-stop')
        if (response.code === 200) {
          this.passwordSent = true
        } else {
          this.$store.dispatch('notification/spawnNotification', {
            type: 'error',
            message: i18n.t(response.result) || i18n.t('Error while sending reset password e-mail'),
            action1: { label: i18n.t('OK'), action: 'close' }
          })
        }
      }).catch((err) => {
        this.$bus.$emit('notification-progress-stop', err)
      })
    },
    switchElem () {
      this.$store.commit('ui/setAuthElem', 'login')
    }
  }
}
</script>

<style lang="scss" scoped>
  $brown-2: #8f7b4c;
  .forgot {
    background-image: url("/assets/Auth/login-bg.jpg");
    &:before {
      @apply h-full absolute w-full left-0 top-0;
      background-color: rgba(0, 0, 0, .4);
      content: '';
    }
    &:after {
      @apply h-full absolute w-full left-0 top-0 bg-center bg-repeat;
      background-image: url("/assets/Auth/pattern-img-tile.png");
      content: '';
    }
    &__content {
      margin: 0 auto;
      max-width: 500px;
    }
    &__title {
      font-size: 50px;
      letter-spacing: .27em;
    }
  }
  .forgot-password {
    &__input {
      &::v-deep {
        input {
          @apply bg-white border-0 text-above-md pl-13;
          height: 60px;
        }
        .icon {
          top: 22px;
        }
      }
      .ss-gizmo {
        font-size: 19px;
        height: 60px;
        left: 15px;
        line-height: 60px;
        top: 3px;
        width: 19px;
      }
    }
    &__description {
      line-height: 32px;
    }
    &__submit {
      border-width: 1px;
      border-color: $brown-2;
      color: $brown-2;
      height: 60px;
      line-height: 60px;
      padding: 0 40px 0 20px;
      text-align: left;
      transition: background-color .5s ease, border-color .5s ease, color .5s ease;
      width: 170px;
      .ss-gizmo {
        line-height: 62px;
        right: 20px;
      }
      &:hover {
        @apply bg-white border-black text-black;
      }
    }
    &__link {
      transition: color .25s ease;
      .ss-gizmo {
        top: 2px;
      }
      &:hover {
        @apply text-brown-2;
      }
    }
  }
</style>
