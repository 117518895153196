<template>
  <div id="reset-password" class="reset-password relative bg-center bg-no-repeat bg-cover">
    <section class="container">
      <div class="flex align-center flex-col justify-center min-h-screen reset-password__content relative z-1">
        <h1 class="uppercase reset-password__title text-white text-center leading-lg font-black mb-30px">
          {{ $t('Set a New Password') }}
        </h1>
        <reset-pass/>
      </div>
    </section>
  </div>
</template>

<script>
import rootStore from '@vue-storefront/core/store'
import ResetPass from 'theme/components/core/blocks/Auth/ResetPass'

export default {
  name: 'ResetPassword',
  components: {
    ResetPass
  },
  beforeRouteEnter (to, from, next) {
    if (rootStore.getters['user/isLoggedIn']) {
      if (to.query.redirect) {
        next(to.query.redirect)
      } else {
        next({name: 'my-account'})
      }
    }
    next()
  }
}
</script>
<style lang="scss" scoped>
  .reset-password {
    background-image: url("/assets/Auth/login-bg.jpg");
    &:before {
      @apply h-full absolute w-full left-0 top-0;
      background-color: rgba(0, 0, 0, .4);
      content: '';
    }
    &:after {
      @apply h-full absolute w-full left-0 top-0 bg-center bg-repeat;
      background-image: url("/assets/Auth/pattern-img-tile.png");
      content: '';
    }
    &__content {
      margin: 0 auto;
      max-width: 500px;

    }
    &__title {
      font-size: 50px;
      letter-spacing: .28em;
    }
  }
</style>
