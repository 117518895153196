var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"forgot relative bg-center bg-no-repeat bg-cover",attrs:{"id":"forgot-your-password"}},[_c('section',{staticClass:"container"},[_c('div',{staticClass:"flex align-center flex-col justify-center min-h-screen forgot__content relative z-1"},[_c('h1',{staticClass:"uppercase text-white forgot__title text-center font-black mb-lg leading-lg"},[_vm._v("\n        "+_vm._s(_vm.$t('Forgot Your Password?'))+"\n      ")]),_vm._v(" "),_c('div',{staticClass:"w-full"},[(!_vm.passwordSent)?[_c('form',{attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.sendEmail($event)}}},[_c('div',{staticClass:"forgot-password"},[_c('p',{staticClass:"forgot-password__description font-serif font-normal text-2h6 text-white text-center italic mb-4"},[_vm._v("\n                "+_vm._s(_vm.$t('Please enter your email address below.'))+" "),_c('br'),_vm._v(" "+_vm._s(_vm.$t('You will receive a link to reset your password.'))+"\n              ")]),_vm._v(" "),_c('base-input',{staticClass:"forgot-password__input mb-sm relative",attrs:{"placeholder":_vm.$t('Email'),"validations":[
                  {
                    condition: !_vm.$v.email.required && _vm.$v.email.$error,
                    text: _vm.$t('Field is required.')
                  },
                  {
                    condition: !_vm.$v.email.email && _vm.$v.email.$error,
                    text: _vm.$t('Please provide valid e-mail address.')
                  }
                ],"focus":"","name":"email","type":"email","input-class":'bg-grey-5 text-black'},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}},[_c('i',{staticClass:"ss-gizmo ss-mail text-brown-2 absolute z-1"})])],1),_vm._v(" "),_c('button-full',{staticClass:"forgot-password__submit relative mb-5",attrs:{"type":"submit"}},[_vm._v("\n              "+_vm._s(_vm.$t('Submit'))+"\n              "),_c('i',{staticClass:"ss-gizmo ss-right absolute top-0 text-h5"})])],1),_vm._v(" "),_c('router-link',{staticClass:"forgot-password__link text-white text-average",attrs:{"to":_vm.localizedRoute('/customer/account/login'),"title":_vm.$t('Sign in')}},[_vm._t("default",[_c('i',{staticClass:"ss-gizmo ss-navigateleft relative"}),_vm._v("\n              "+_vm._s(_vm.$t('Go back'))+"\n            ")])],2)]:_vm._e(),_vm._v(" "),(_vm.passwordSent)?[_c('p',{staticClass:"forgot-password__description bg-primary2 text-white py-3 px-5 mb-3 font-bold"},[_vm._v("\n            "+_vm._s(_vm.$t('We\'ve sent password reset instructions to your email.'))+" "),_c('br'),_vm._v(" "+_vm._s(_vm.$t('Check your inbox and follow the link.'))+"\n          ")]),_vm._v(" "),_c('button-full',{staticClass:"forgot-password__submit relative mb-5",attrs:{"link":_vm.localizedRoute('/customer/account/login'),"title":_vm.$t('Sign in')}},[_vm._v("\n            "+_vm._s(_vm.$t('sign in'))+"\n            "),_c('i',{staticClass:"ss-gizmo ss-right absolute top-0 text-h5"})])]:_vm._e()],2)])])])}
var staticRenderFns = []

export { render, staticRenderFns }