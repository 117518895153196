var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full"},[_c('form',{attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.setNewPassword($event)}}},[_c('div',{},[_c('div',{staticClass:"text-white text-center mb-3"},[_vm._v("\n        "+_vm._s(_vm.$t('Please enter your email and new password below.'))+"\n      ")]),_vm._v(" "),_c('base-input',{attrs:{"input-class":{'bg-grey-5': _vm.emailDisabled},"label-class":"text-white","placeholder":_vm.$t('E-mail *'),"validations":[
            {
              condition: !_vm.$v.email.required && _vm.$v.email.$error,
              text: _vm.$t('Field is required.')
            },
            {
              condition: !_vm.$v.email.email && _vm.$v.email.$error,
              text: _vm.$t('Please provide valid e-mail address.')
            }
          ],"disabled":_vm.emailDisabled,"name":"email","type":"email","autocomplete":false,"focus":!_vm.emailDisabled},on:{"blur":function($event){return _vm.$v.email.$touch()}},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}},[_vm._v(_vm._s(_vm.$t('Email *'))+"\n      ")]),_vm._v(" "),_c('base-input',{staticClass:"mt-4",attrs:{"label-class":"text-white","placeholder":_vm.$t('New Password *'),"validations":[
          {
            condition: !_vm.$v.password.required && _vm.$v.password.$error,
            text: _vm.$t('Field is required.')
          },
          {
            condition: !_vm.$v.password.minLength && _vm.$v.password.$error,
            text: _vm.$t('Password must have at least 8 letters.')
          },
          {
            condition: !_vm.$v.password.containsUppercase && _vm.$v.password.$error,
            text: _vm.$t('Must contain at least one uppercase letter.')
          },
          {
            condition: !_vm.$v.password.containsLowercase && _vm.$v.password.$error,
            text: _vm.$t('Must contain at least one lowercase letter.')
          },
          {
            condition: !_vm.$v.password.containsNumber && _vm.$v.password.$error,
            text: _vm.$t('Must contain at least one number.')
          },
          {
            condition: !_vm.$v.password.containsSpecial && _vm.$v.password.$error,
            text: _vm.$t('Must contain at least one special character.')
          }
        ],"focus":"","type":"password","name":"password","autocomplete":"off"},on:{"blur":function($event){return _vm.$v.password.$touch()}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}},[_vm._v(_vm._s(_vm.$t('New Password *'))+"\n      ")]),_vm._v(" "),_c('base-input',{staticClass:"mt-4",attrs:{"label-class":"text-white","placeholder":_vm.$t('Confirm New Password *'),"validations":[
          {
            condition: !_vm.$v.rPassword.required && _vm.$v.rPassword.$error,
            text: _vm.$t('Field is required.')
          },
          {
            condition: !_vm.$v.rPassword.sameAsPassword && _vm.$v.rPassword.$error,
            text: _vm.$t('Passwords must be identical.')
          }
        ],"type":"password","name":"password-confirm","autocomplete":"off"},on:{"blur":function($event){return _vm.$v.rPassword.$touch()}},model:{value:(_vm.rPassword),callback:function ($$v) {_vm.rPassword=$$v},expression:"rPassword"}},[_vm._v(_vm._s(_vm.$t('Confirm New Password *'))+"\n      ")])],1),_vm._v(" "),_c('button-full',{staticClass:"mt-5 w-full",attrs:{"type":"submit"}},[_vm._v("\n      "+_vm._s(_vm.$t('Set a New Password'))+"\n    ")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }